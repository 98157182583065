'use client';

import { Provider } from 'react-redux';
import { store } from './store';
import { PersistGate } from "redux-persist/integration/react";
// import persistStore from "redux-persist/es/persistStore";
import {persistor} from '@/app/reduxStore/store'

export function Providers({ children }) {
    return (
        <Provider store={store}>
             <PersistGate persistor={persistor}>
            {children}
            </PersistGate>
        </Provider>
    )
}