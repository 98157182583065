'use client';

import { createSlice } from '@reduxjs/toolkit';

export const activeLink = createSlice({
    name: 'link',
    initialState:{
       activeLink : 'home'
    },
    reducers: {
        setNavbar: (state,action) => {
            state.activeLink=action.payload
            return state
        },
    }
})

export const {setNavbar } = activeLink.actions;

export default activeLink.reducer;