import(/* webpackMode: "eager" */ "/app/app/reduxStore/provider.js");
import(/* webpackMode: "eager" */ "/app/components/Header.js");
import(/* webpackMode: "eager" */ "/app/node_modules/@auth0/nextjs-auth0/dist/client/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/bootstrap/dist/css/bootstrap.min.css");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Button.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Col.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Container.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FloatingLabel.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormCheck.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormContext.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormControl.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormFloating.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormLabel.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormRange.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormSelect.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/FormText.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-bootstrap/esm/Row.js");
import(/* webpackMode: "eager" */ "/app/styles/global.scss")