import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { server } from "@/config/server";
import axios from 'axios';
import { toast } from 'react-toastify';


// export const getVid = createAsyncThunk('contacts/getVid', async (payload, thunkAPI) => {
//     try {
//         const response = await axios.get(`${server}/contacts?email=${payload}`);
//         console.log(response?.data.profile,"heyyyyyyy")
//         return response?.data.profile.vid
//     } catch (error) {
//         console.log(error)
//     }

//     return data

// })
// export const getContact = createAsyncThunk('contacts/getContact', async (payload, thunkAPI) => {
//     try {
//         const response = await axios.get(`${server}/contacts/${payload}`);
//         console.log(response.data,"lissss")
//         return response?.data
//     } catch (error) {
//         console.log(error)
//     }

//     return data

// })
export const createContact = createAsyncThunk('contacts/createContact', async (payload, thunkAPI) => {
    try {
        const response = await axios.post(`${server}/contacts`,payload);
        return response?.data?.contact
    } catch (error) {
        console.log(error)
    }

    return data

})

export const createContactDetails = createAsyncThunk('contacts/createContact', async (payload, thunkAPI) => {
    try {
        const response = await axios.post(`${server}/contact_details`,payload);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})
export const contactDetailsAssociation = createAsyncThunk('contacts/contactDetailsAssociation', async (payload, thunkAPI) => {
    try {
        const response = await axios.put(`${server}/contact_details?contact_detail_id=${payload.contact_details}&contact_id=${payload.contact_id}`);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

export const contactSlice = createSlice({
    name: 'contacts',
    initialState:{
    //    vidDetails : {},
    //    contactDetails:{},
       createContactRes : {},
       createContactDetailsRes : {},
       contact_detailsAssociation : {},
       activeLink:''
    },
    reducers: {
        setNavba: (state,action) => {
            state.activeLink=action.payload
            return state
        },
        extraReducers: (builder) => {
            // builder.addCase(getVid.fulfilled, (state, action) => {
            //     console.log("hehhehehehehheheheh")
            //     state.vidDetails = action.payload;
            //     return state;
            // }),
            // builder.addCase(getContact.fulfilled, (state, action) => {
            //     console.log(action.payload,"raviiiiiiiiiiiiii")
            //     state.contactDetails = action.payload;
            //     return state;
            // }),
            builder.addCase(createContact.fulfilled, (state, action) => {
                state.createContactRes = action.payload;
                return state;
            }),
            builder.addCase(createContactDetails.fulfilled, (state, action) => {
                state.createContactDetailsRes = action.payload;
                return state;
            }),
            builder.addCase(contactDetailsAssociation.fulfilled, (state, action) => {
                state.contact_detailsAssociation = action.payload;
                return state;
            })
        },
    }
})

export const {setNavba } = contactSlice.actions;

export default contactSlice.reducer;