"use client";
import React, { useState } from "react";
import Link from "next/link";
import { FaBars } from "react-icons/fa";
import Image from "next/image";
// import logo from "../public/static/images/logo.svg";
import { useUser } from "@auth0/nextjs-auth0/client";
import { useDispatch, useSelector } from "react-redux";
import logo from "../public/assets/logos.png";
import { setUser } from "@/app/reduxStore/Reducers/authReducer";
import { useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { setNavbar } from "@/app/reduxStore/Reducers/activeLinkReducer";
import { RiArrowDropDownLine } from "react-icons/ri";
import { IoOptions } from "react-icons/io5";
import { LuSettings2 } from "react-icons/lu";
import Badge from "react-bootstrap/Badge";
import { GoSignIn } from "react-icons/go";
import { getCustomer, getSubscription } from "@/app/reduxStore/Reducers/stripe";
import { MdPriceCheck } from "react-icons/md";
import { IoPricetagsOutline } from "react-icons/io5";
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation'
import clearCookies from "./cookies";

const Header = () => {
  const pathname = usePathname()
  const { user, error, isLoading } = useUser();
  const userData = useSelector((state) => state.reducer.auth.user);
  const subsricptionData = useSelector(
    (state) => state.reducer.stripe.subsricptionData
  );
  const cancelSubResponse = useSelector(
    (state) => state.reducer.stripe.cancelSubResponse
  );
  const createsubscribeRes = useSelector(
    (state) => state.reducer.stripe.createsubscribeRes
  );
  const activeLink = useSelector(
    (state) => state.reducer.activeLink.activeLink
  );
  const [mobileView, setMobileView] = useState(false);
  const dispatch = useDispatch();
  
  const [isToggle, setToggle] = useState(false);

  const handleDropdownToggle = (event) => {
    const target = event.target;
    const parent = target.parentElement;

    if (target.classList.contains("dropdown-toggle")) {
      setToggle(!isToggle);
    } else if (parent && parent.classList.contains("dropdown-toggle")) {
      setToggle(!isToggle);
    }
  };

  useEffect(() => {
    if (!userData) {
      // dispatch(setUser(user));
    } else {
      dispatch(getCustomer(user?.email)).then((res) => {
        dispatch(getSubscription(res?.payload?.[0]?.id));
      });
    }
  }, [user, cancelSubResponse, createsubscribeRes]);

  const onLogOut = () => {
    setTimeout(()=> {
      clearCookies()
      dispatch(setUser(null));
      window.location.replace("https://auth.cognogent.com/v2/logout?client_id=BouV3Wj89Z9ioRJejYwslIY45Z0vF4dP&returnTo=https://cognogent.com/api/auth/logout")
    }, 1000)
  };
useEffect(() => {
  if(pathname == "/company") dispatch(setNavbar("company"));
  if(pathname == "/products") dispatch(setNavbar("products"));
  if(pathname == "/pricing") dispatch(setNavbar("pricing"));
  if(pathname == "/contactus") dispatch(setNavbar("contactus"));
  if(pathname == "/blog") dispatch(setNavbar("blog"));
},[])
  return (
    <div className="container-fluid p-0">
      <header id="header" className="">
        <nav
          id="navbar"
          className={mobileView ? "navbar navbar-mobile" : "navbar"}
        >
          <div className="container header_container">
            <Link
              className="navbar-brand"
              href="/"
              onClick={() => dispatch(setNavbar("home"))}
            >
              <div className="logo">
                <Image src={logo} alt="icon" />
              </div>
            </Link>

            <div
              className="collapse navbar-collapse w-100 justify-content-between"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav nav-tabbs mx-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className={
                      activeLink === "home" ? "nav-link active" : "nav-link"
                    }
                    aria-current="page"
                    href="/"
                    onClick={() => {
                      dispatch(setNavbar("home"));
                      setTimeout(() => {
                        setMobileView(false);
                      }, 500);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      activeLink === "company" ? "nav-link active" : "nav-link"
                    }
                    href="/company"
                    onClick={() => {
                      dispatch(setNavbar("company"));
                      setTimeout(() => {
                        setMobileView(false);
                      }, 500);
                    }}
                  >
                    Company
                  </Link>
                   
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className={
                      activeLink === "products" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      dispatch(setNavbar("products"));
                      setTimeout(() => {
                        setMobileView(false);
                      }, 500);
                    }}
                    href="/products"
                    role="button"
                    // data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Products
                  </Link>
                  {/* <ul className="dropdown-menu">
                 <li>
                   <Link className="dropdown-item" href="#">
                     Action
                   </Link>
                 </li>
                 <li>
                   <Link className="dropdown-item" href="#">
                     Another action
                   </Link>
                 </li>
                 <li>
                   <hr className="dropdown-divider" />
                 </li>
                 <li>
                   <Link className="dropdown-item" href="#">
                     Something else here
                   </Link>
                 </li>
               </ul> */}
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      activeLink === "pricing" ? "nav-link active" : "nav-link"
                    }
                    onClick={() => {
                      dispatch(setNavbar("pricing"));
                      setTimeout(() => {
                        setMobileView(false);
                      }, 500);
                    }}
                    href="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      activeLink === "blog" ? "nav-link active" : "nav-link"
                    }
                    href="/blog"
                    onClick={() => {
                      dispatch(setNavbar("blog"));
                      setTimeout(() => {
                        setMobileView(false);
                      }, 500);
                    }}
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={
                      activeLink === "contactus"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => {
                      dispatch(setNavbar("contactus"));
                      setTimeout(() => {
                        setMobileView(false);
                      }, 500);
                    }}
                    href="/contactus"
                  >
                    Contact Us
                  </Link>
                </li>
                {/* <li className="nav-item d-flex">
               {userData && (
              <Link
                  className="nav-link logout  d_aic_jcc"
                  href="/api/auth/logout"
                   onClick={onLogOut}
               >
              Logout
              </Link>
               )}
             </li> */}
              </ul>

              {!user ? (
                <div className="signin_parent">
                  <Link className="signin" href="/api/auth/login">
                    Sign In
                  </Link>
                </div>
              ) : (
                <>
                  <div
                    className="collapse navbar-collapse profile me-lg-0 me-3"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav mb-lg-0 profile-menu">
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link dropdown-toggle d-flex align-items-center"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d_aic_jcc gap-1 signout">
                            <div className="profile_avatar d_aic_jcc">
                              <span>
                                <div className="profilepic">
                                  <img src={user?.picture} alt="profile" />
                                </div>
                                {/* {userData?.given_name
                                  ?.slice(0, 1)
                                  .toUpperCase()}
                                  {userData?.family_name
                                  ?.slice(0, 1)
                                  .toUpperCase()} */}
                                {/* HR */}
                              </span>
                            </div>
                            {/* <h3 className="m-0">{userData?.given_name}</h3> */}
                            <RiArrowDropDownLine className="fs-3" />
                          </div>
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li className="d-flex align-items-center gap-3 border-bottom py-2">
                            <div className="profile_avatar d_aic_jcc">
                              <span>
                                <div className="profilepic">
                                  <img src={user?.picture} alt="profile" />
                                </div>
                                {/* {userData?.given_name
                                  ?.slice(0, 1)
                                  .toUpperCase()}
                                    {userData?.family_name
                                  ?.slice(0, 1)
                                  .toUpperCase()} */}
                                {/* HR */}
                              </span>
                            </div>
                            <div className="dropdown-profilename">
                              <h6 className="m-0 text-capitalize">
                                {user?.given_name}
                                <span className="m-0 text-lowercase">
                                  {user?.email}
                                </span>
                              </h6>
                            </div>
                          </li>
                          <li className="py-2 pb-0">
                            <Link
                              className="dropdown-item"
                              href="/profile"
                              onClick={() => dispatch(setNavbar())}
                            >
                              <LuSettings2 className="text-dark me-2 fs-5" />{" "}
                              Profile Settings
                            </Link>
                          </li>
                          <li className="border-bottom py-2 pt-0">
                            <Link
                              class="dropdown-item"
                              href="/pricing"
                              onClick={() => dispatch(setNavbar("pricing"))}
                            >
                              <IoPricetagsOutline className="text-dark me-2 fs-5" />{" "}
                              Pricing
                            </Link>
                          </li>
                          {/* <li className="d-flex align-items-center justify-content-between plan-dropdown-field">
                            <div>
                              <h6 className="m-0">Free Plan</h6>
                              <p className="m-0">$ 0.00 yr</p>
                            </div>
                            <div>
                              <p className="m-0">
                                <Badge bg="secondary">Upgrade Plan</Badge>
                              </p>
                            </div>
                          </li> */}
                          {/* <li>
                            <hr className="dropdown-divider" />
                          </li> */}
                          <li>
                            <Link
                              className="dropdown-item"
                              href="/logout"
                            >
                              <GoSignIn className="text-dark me-2 fs-5" /> Sign
                              Out
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
            <div className="d-block d-lg-none bars_responsive">
              {!mobileView && (
                <FaBars
                  className="mobile-nav-toggle fs-5"
                  onClick={() => {
                    setMobileView(true);
                  }}
                />
              )}
              {mobileView && (
                <IoClose
                  className=" mobile-nav-toggle"
                  onClick={() => {
                    setMobileView(false);
                  }}
                />
              )}
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
