'use client';

import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { server } from "@/config/server";
import axios from 'axios';
import { toast } from 'react-toastify';


export const getCustomer = createAsyncThunk('stripe/getcustomer', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/customers?email=${payload}`);
        return response?.data?.customers?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

//get subscription details
export const getSubscription = createAsyncThunk('stripe/getSubscription', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/subscriptions?customer=${payload}`);
        return response.data?.subscriptions?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

//cancel subscription 
export const cancelSubscription = createAsyncThunk('stripe/cancelSubscription', async (payload, thunkAPI) => {
    try {
        const response = await axios.delete(`${server}/subscriptions/${payload}`);
        if(response.data.subscriptions.status == 'canceled')   toast.success(response.data.subscriptions.status);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

//Plan list

export const getPlans = createAsyncThunk('stripe/getPlans', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/subscriptions`);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

export const getAllPlans = createAsyncThunk('stripe/getAllPlans', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/products`);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

export const createCustomer = createAsyncThunk(
    "stripe/createCustomer",
    async (payload, thunkAPI) => {
      try {
        const response = await axios.post(
          `${server}/customers`,
          payload
        );
         return response.data
      } catch (error) {
        console.log(error);
      }
      return 
    }
  );

  export const createSubscribe = createAsyncThunk(
    "stripe/createSubscribe",
    async (payload, thunkAPI) => {
      try {
        const response = await axios.post(
          `${server}/subscriptions`,
          payload
        );
         return response.data
      } catch (error) {
        console.log(error);
      }
      return 
    }
  );

  //get invoice

  export const getInvoice = createAsyncThunk('stripe/getInvoice', async (payload, thunkAPI) => {
    
    try {
        const response = await axios.get(`${server}/invoices?customer=${payload}`);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

//send invoice

export const sendInvoice = createAsyncThunk('stripe/sendInvoice', async (payload, thunkAPI) => {
    try {
        const response = await axios.post(`${server}/invoices/${payload}`);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})

export const getVid = createAsyncThunk('stripe/getVid', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/contacts?email=${payload}`);
        return response?.data.profile.vid
    } catch (error) {
        console.log(error)
    }

    return data

})
export const getContact = createAsyncThunk('stripe/getContact', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/contacts/${payload}`);
        return response?.data
    } catch (error) {
        console.log(error)
    }

    return data

})
export const addAddress = createAsyncThunk('stripe/addAddress', async (payload, thunkAPI) => {
    try {
        const response = await axios.post(`${server}/address`,{properties: payload});
        return response?.data.address?.id
    } catch (error) {
        console.log(error)
    }

    return data

})

export const assosiateAddress = createAsyncThunk('stripe/assosiateAddress', async (payload, thunkAPI) => {
    try {
        const response = await axios.put(`${server}/address?address_id=${payload.address_id}&contact_id=${payload.contact_id}`);
        return response?.data.address?.id
    } catch (error) {
        console.log(error)
    }

    return data

})
export const getAddress = createAsyncThunk('stripe/getAddress', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/address/${payload}`);
        return response?.data?.address
    } catch (error) {
        console.log(error)
    }

    return data

})

export const getContactDetails = createAsyncThunk('stripe/getContactDetails', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/contact_details/${payload}`);
        return response?.data
    } catch (error) {
        console.log(error)
    }
    return data

})

export const getUserCompanyDetails = createAsyncThunk('stripe/getUserCompanyDetails', async (payload, thunkAPI) => {
    try {
        const response = await axios.get(`${server}/company?user_id=${payload}`);
        return response?.data
    } catch (error) {
        console.log(error)
    }
    return data
})
export const addUserCompanyDetails = createAsyncThunk('stripe/addUserCompanyDetails', async (payload, thunkAPI) => {
    try {
        const response = await axios.post(`${server}/company_name`,{company_name:payload?.company_name,user_id:payload?.user_id});
        return response?.data
    } catch (error) {
        console.log(error)
    }
    return data
})
export const editUserCompanyDetails = createAsyncThunk('stripe/editUserCompanyDetails', async (payload, thunkAPI) => {
    try {
        const response = await axios.patch(`${server}/company_name`,{payload});
        return response?.data
    } catch (error) {
        console.log(error)
    }
    return data
})

export const stripeSlice = createSlice({
    name: 'stripe',
    initialState: {
        customerData: {},
        subsricptionData : {},
        cancelSubResponse : {},
        planList : {},
        newCustomerRes : {},
        createsubscribeRes:{},
        getInvoiceRes:{},
        sendInvoiceRes :{},
        vidDetails : {},
        contactDetails:{},
        addAddressResponse : {},
        addressAssociationResponse : {},
        userAddress : {},
        allPlans : {},
        phoneNumber : {},
        userCompanyDetails:{},
        userCompanyDetailsRes:{},
        userCompanyDetailsResEdit:{}
    },
    // reducers: {
    //     setUser: (state, action) => {
    //         state.user = action.payload
    //         return state
    //     },
    // },
    extraReducers: (builder) => {
        builder.addCase(getCustomer.fulfilled, (state, action) => {
            state.customerData = action.payload;
            return state;
        }),
        builder.addCase(getSubscription.fulfilled, (state, action) => {
            state.subsricptionData = action.payload;
            return state;
        }),
        builder.addCase(cancelSubscription.fulfilled, (state, action) => {
            state.cancelSubResponse = action.payload;
            return state;
        }),
        builder.addCase(getPlans.fulfilled, (state, action) => {
            state.planList = action.payload;
            return state;
        }),
        builder.addCase(createCustomer.fulfilled, (state, action) => {
            state.newCustomerRes = action.payload;
            return state;
        }),
        builder.addCase(createSubscribe.fulfilled, (state, action) => {
            state.createsubscribeRes = action.payload;
            return state;
        }),
        builder.addCase(getInvoice.fulfilled, (state, action) => {
            state.getInvoiceRes = action.payload;
            return state;
        }),
        builder.addCase(sendInvoice.fulfilled, (state, action) => {
            state.sendInvoiceRes = action.payload;
            return state;
        }),
        builder.addCase(getVid.fulfilled, (state, action) => {
            state.vidDetails = action.payload;
            return state;
        }),
        builder.addCase(getContact.fulfilled, (state, action) => {
            state.contactDetails = action.payload;
            return state;
        }),
        builder.addCase(addAddress.fulfilled, (state, action) => {
            state.addAddressResponse = action.payload;
            return state;
        }),
        builder.addCase(assosiateAddress.fulfilled, (state, action) => {
            state.addressAssociationResponse = action.payload;
            return state;
        }),
        builder.addCase(getAddress.fulfilled, (state, action) => {
            state.userAddress = action.payload;
            return state;
        }),
        builder.addCase(getAllPlans.fulfilled, (state, action) => {
            state.allPlans = action.payload;
            return state;
        }),
        builder.addCase(getContactDetails.fulfilled, (state, action) => {
            state.phoneNumber = action.payload;
            return state;
        }),
        builder.addCase(getUserCompanyDetails.fulfilled, (state, action) => {
            state.userCompanyDetails = action.payload;
            return state;
        }),
        builder.addCase(addUserCompanyDetails.fulfilled, (state, action) => {
            state.userCompanyDetailsRes = action.payload;
            return state;
        }),
        builder.addCase(editUserCompanyDetails.fulfilled, (state, action) => {
            state.userCompanyDetailsResEdit = action.payload;
            return state;
        })
        
    },
})

export const { setUser } = stripeSlice.actions;

export default stripeSlice.reducer;