'use client';
import { configureStore,combineReducers } from '@reduxjs/toolkit';
import auth from './Reducers/authReducer';
import { persistReducer,persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import activeLink  from './Reducers/activeLinkReducer';
import stripe from './Reducers/stripe';
import contacts from './Reducers/contacts';

const persistConfig = {
    key: 'persist',
    storage,
    whitelist: ['auth'] // which reducer want to store
  };

const rootReducer = combineReducers({ auth: auth,activeLink:activeLink,stripe:stripe,contacts:contacts})

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: {
        reducer: pReducer,
    }
})

export const persistor=persistStore(store)