import Cookies from 'js-cookie';

export default function clearCookies() {
  // Clear specific cookies by providing their names
//   Cookies.remove('auth_verification',{ path: 'https://cognogent.com' });
//   Cookies.remove('hubspotutk',{ path: 'https://cognogent.com' });
//   Cookies.remove('_hjSessionUser_744283', { path: 'https://cognogent.com' })
  
  // Alternatively, you can clear all cookies
  Cookies.remove();
}
